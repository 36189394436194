import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { GameComponent } from './components/game/game.component';
import { DesktopComponent } from './components/desktop/desktop.component';
import { MobileComponent } from './components/mobile/mobile.component';
import { FieldComponent } from './components/mobile/field/field.component';
import { HudComponent } from './components/mobile/hud/hud.component';
import { PlayerComponent } from './components/mobile/player/player.component';

import { routes } from './app.routes';
import { GameEffects } from './state/game/game.effects';
import { gameReducer } from './state/game/game.reducer';
import { localStorageMetaReducer } from './state/local-storage.metareducer';
import { SignalRService } from './services/signal-r.service';
import { CardAltTextPipe } from './pipes/card-alt-text.pipe';
import { CardImageUrlPipe } from './pipes/card-image-url.pipe';
import { ModalComponent } from './components/common/modal/modal.component';
import { RotatingCirclesComponent } from './components/vfx/rotating-circles/rotating-circles.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    GameComponent,
    DesktopComponent,
    MobileComponent,
    FieldComponent,
    HudComponent,
    PlayerComponent,
    CardImageUrlPipe,
    CardAltTextPipe,
    ModalComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    StoreModule.forRoot(
      { game: gameReducer },
      { metaReducers: [localStorageMetaReducer] }
    ),
    EffectsModule.forRoot([GameEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    RotatingCirclesComponent,
  ],
  providers: [SignalRService],
  bootstrap: [AppComponent],
})
export class AppModule {}
