<div
  class="background-image w-screen h-screen flex justify-center items-center"
  *ngIf="!showGameView"
>
  <div
    class="flex flex-col gap-2 justify-center items-stretch bg-burizaWhite p-4 text-center"
  >
    <h1 class="text-burizaDark text-3xl font-bold">Welcome to Buriza</h1>

    <p class="text-burizaDark">To join a room enter your nickname.</p>
    <input
      class="p-2 focus:outline-2 focus:outline-burizaViolet rounded-md"
      type="text"
      placeholder="Enter your nickname"
      [(ngModel)]="playerName"
    />
    <button class="p-2 bg-burizaRed rounded-md" (click)="joinGame()">
      Join Game
    </button>
  </div>
</div>

<div class="container" *ngIf="showGameView && (game$ | async)?.isStarted">
  <ng-container *ngIf="isMobile; else desktopView">
    <app-mobile></app-mobile>
  </ng-container>
  <ng-template #desktopView>
    <app-desktop></app-desktop>
  </ng-template>
</div>

<div
  class="container"
  *ngIf="showGameView && !showGameEndModal && !(game$ | async)?.isStarted"
>
  <button (click)="startGame()">Start</button>
</div>

<app-modal
  [show]="showGameEndModal"
  [title]="'Game Over'"
  (close)="showGameEndModal = false"
>
  <div class="game-end-content">
    <h3>Final Scores</h3>
    <div
      *ngFor="let player of currentGame?.players | keyvalue"
      class="player-score"
    >
      <span>{{ player.key }}:</span>
      <span>{{ player.value.score }}</span>
    </div>
    <button (click)="goToHomePage()">Ok</button>
  </div>
</app-modal>
