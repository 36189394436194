import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  PLATFORM_ID,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Game } from '../../state/game/game.models';
import { Observable, Subscription } from 'rxjs';
import { selectCurrentGame } from './../../state/game/game.selectors';
import * as GameActions from './../../state/game/game.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { SignalRService } from '../../services/signal-r.service';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrl: './game.component.css',
})
export class GameComponent implements OnInit, OnDestroy {
  isMobile: boolean = true;
  game$: Observable<Game | null>;
  showGameView: boolean = false;
  playerName: string = '';
  gameName: string = '';
  private gameSubscription: Subscription | null = null;
  showGameEndModal: boolean = false;
  currentGame: Game | null = null;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store<Game>,
    private router: Router,
    private route: ActivatedRoute,
    private SignalRService: SignalRService
  ) {
    this.game$ = this.store.select(selectCurrentGame);
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.gameName = params['game_id'];
    });

    this.gameSubscription = this.game$.subscribe((game) => {
      this.checkIfShowGameView(game);
      if (game) {
        this.currentGame = game;
        console.log('Current game:', game);
        // Do something with the game data
        // localStorage.setItem('game', JSON.stringify(game));
        this.checkGameEnd(game);
      }
    });
    this.checkScreenSize();
  }

  ngOnDestroy() {
    if (this.gameSubscription) {
      this.gameSubscription.unsubscribe();
    }
  }

  checkIfShowGameView(game: Game | null) {
    // debugger;
    let player = localStorage.getItem('username');
    if (
      game &&
      game.name === this.gameName &&
      player &&
      player in game.players
    ) {
      this.showGameView = true;
    } else {
      this.showGameView = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 768; // Adjust this breakpoint as needed
    }
  }

  joinGame() {
    if (this.playerName && this.gameName) {
      localStorage.setItem('username', this.playerName);
      this.store.dispatch(
        GameActions.joinGame({
          gameName: this.gameName,
          playerName: this.playerName,
        })
      );
    }
  }

  startGame() {
    this.SignalRService.startGame(this.gameName);
  }

  checkGameEnd(game: Game) {
    this.showGameEndModal =
      !game.isStarted &&
      !game.isBattlingPhase &&
      !game.isPredictingPhase &&
      Object.values(game.players).some((p) => p.score > 0);
  }

  goToHomePage() {
    this.showGameEndModal = false; // Close the modal
    this.router.navigate(['/']); // Navigate to the home page
  }
}
