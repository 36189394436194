import {
  Component,
  Input,
  ChangeDetectionStrategy,
  SimpleChanges,
} from '@angular/core';
import { Player, Card, Game } from '../../../state/game/game.models';
import { select, Store } from '@ngrx/store';

import {
  selectPlayerByName,
  selectCurrentPlayer,
  selectPlayersLastCard,
  selectPlayerActualWins,
} from '../../../state/game/game.selectors';

import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css'],
  // animations: [
  //   trigger(':enter', [
  //     state('normal', style({ transform: 'translateY(0)' })),
  //     state('bounced', style({ transform: 'translateY(-10px)' })),
  //     transition('normal <=> bounced', [animate('0.5s ease-in-out')]),
  //   ]),
  // ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayerComponent {
  currentlyPlaying$: boolean = false;
  @Input() playerName: string | null = null;
  player$: Observable<Player | null> = new Observable<Player>();
  predictedWins$: Observable<number> = new Observable<number>();
  actualWins$: Observable<number> = new Observable<number>();
  lastPlayedCard$: Observable<Card | null> = new Observable<Card | null>();
  renders: number = 0;
  ngOnInit() {
    if (this.playerName) {
      this.store.select(selectCurrentPlayer).subscribe((player) => {
        this.currentlyPlaying$ = player === this.playerName;
      });

      this.player$ = this.store.select(selectPlayerByName(this.playerName));
      this.actualWins$ = this.store.select(
        selectPlayerActualWins(this.playerName)
      );
      this.lastPlayedCard$ = this.store.select(
        selectPlayersLastCard(this.playerName)
      );
      this.predictedWins$ = this.store.select(
        selectPlayerActualWins(this.playerName)
      );
    }
  }

  constructor(private store: Store<Game>) {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log('Changes ', changes);
  }
}
