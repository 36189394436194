import { createReducer, on } from '@ngrx/store';
import * as GameActions from './game.actions';
import { GameState } from './game.models';

export const initialState: GameState = {
  game: null,
  error: null,
};

export const gameReducer = createReducer(
  initialState,
  on(GameActions.createGameSuccess, (state, { game }) => {
    console.log('Reducer: createGameSuccess', game);
    return {
      ...state,
      game: game,
      error: null,
    };
  }),
  on(GameActions.createGameFailure, (state, { error }) => {
    console.log('Reducer: createGameFailure', error);
    return {
      ...state,
      error: error,
    };
  }),
  on(GameActions.joinGameSuccess, (state, { game }) => {
    console.log('Reducer: joinGameSuccess', game);
    return {
      ...state,
      game: game,
      error: null,
    };
  }),
  on(GameActions.joinGameFailure, (state, { error }) => {
    console.log('Reducer: joinGameFailure', error);
    return {
      ...state,
      error: error,
    };
  }),
  on(GameActions.updateGameState, (state, { game }) => ({
    ...state,
    game: game,
  })),
  on(GameActions.predictSuccess, (state) => {
    console.log('Reducer: predictSuccess');
    return {
      ...state,
      error: null,
    };
  }),
  on(GameActions.predictFailure, (state, { error }) => {
    console.log('Reducer: predictFailure', error);
    return {
      ...state,
      error: error,
    };
  })
);
