import { createSelector } from '@ngrx/store';
import { GameState } from './game.models';

export const selectGameState = (state: any) => {
  return state.game;
};

export const selectCurrentGame = createSelector(
  selectGameState,
  (state: GameState) => state.game
);

export const selectTargetSign = createSelector(
  selectCurrentGame,
  (currentGame) => {
    let targetCard = currentGame?.tableCard;
    let firstMove = currentGame?.battlingMoves[0];
    if (firstMove) {
      targetCard = firstMove.card;
    }
    return targetCard?.sign;
  }
);

export const selectCurrentPlayer = createSelector(
  selectCurrentGame,
  (currentGame) => (currentGame ? currentGame.playingOrder[0] : null)
);

export const selectPlayerByName = (name: string) =>
  createSelector(selectCurrentGame, (currentGame) =>
    currentGame?.players[name] ? currentGame.players[name] : null
  );

export const selectPlayersLastCard = (name: string) =>
  createSelector(selectPlayerByName(name), (player) =>
    player && player.playedCards.length > 0
      ? player.playedCards[player.playedCards.length - 1]
      : null
  );

export const selectPlayerPredictedWins = (name: string) =>
  createSelector(selectPlayerByName(name), (player) =>
    player ? player.predictedVictories! : 0
  );

export const selectPlayerActualWins = (name: string) =>
  createSelector(selectPlayerByName(name), (player) =>
    player ? player.actualVictories! : 0
  );

export const selectPlayerHandCards = (name: string) =>
  createSelector(selectPlayerByName(name), (player) =>
    player ? player.cards : []
  );

export const selectGameName = createSelector(
  selectCurrentGame,
  (currentGame) => currentGame?.name
);

export const selectPlayerNames = createSelector(
  selectCurrentGame,
  (currentGame) => {
    return currentGame ? Object.keys(currentGame.players) : [];
  }
);

export const selectTableCard = createSelector(
  selectCurrentGame,
  (currentGame) => currentGame?.tableCard
);
