import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rotating-circles',
  templateUrl: './rotating-circles.component.html',
  styleUrl: './rotating-circles.component.css',
  standalone: true,
})
export class RotatingCirclesComponent {
  @Input() colorClass: string = 'border-white';
  @Input() interval: number = 1;
}
