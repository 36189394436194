import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  Game,
  Prediction,
  Move,
  PlayerConnection,
} from '../state/game/game.models';
import { Store } from '@ngrx/store';
import * as GameActions from '../state/game/game.actions';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private hubConnection: HubConnection | null = null;
  private connectionStatus = new BehaviorSubject<boolean>(false);
  private intervalId: any;

  constructor(private store: Store) {}

  startConnection(): Promise<void> {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${environment.gameHubUrl}`)
      .withAutomaticReconnect()
      .withKeepAliveInterval(10000)
      .build();

    this.hubConnection.serverTimeoutInMilliseconds = 120000;

    return this.hubConnection
      .start()
      .then(() => {
        console.log('SignalR connection started');
        this.connectionStatus.next(true);
        this.addListeners();
      })
      .catch((err) =>
        console.error('Error while starting SignalR connection:', err)
      );
  }

  stopConnection(): Promise<void> {
    if (this.hubConnection) {
      return this.hubConnection
        .stop()
        .then(() => {
          console.log('SignalR connection stopped');
          this.connectionStatus.next(false);
        })
        .catch((err) =>
          console.error('Error while stopping SignalR connection:', err)
        );
    }
    return Promise.resolve();
  }

  register(playerConnection: PlayerConnection): Promise<void> {
    if (this.hubConnection) {
      return this.hubConnection
        .invoke('Register', playerConnection)
        .catch((err) => console.error('Error while registering player:', err));
    }
    return Promise.reject('No hub connection');
  }

  private stopPolling(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  private startPolling(): void {
    this.stopPolling(); // Ensure no multiple intervals exist
    this.intervalId = setInterval(() => {
      if (this.hubConnection) {
        this.hubConnection
          .invoke('Heartbeat')
          .catch((err) =>
            console.error('Error while calling AnotherHubMethod:', err)
          );
      }
    }, 5000);
  }

  predict(prediction: Prediction): Promise<void> {
    if (this.hubConnection) {
      return this.hubConnection
        .invoke('Predict', prediction)
        .catch((err) => console.error('Error while making prediction:', err));
    }
    return Promise.reject('No hub connection');
  }

  move(move: Move): Promise<void> {
    if (this.hubConnection) {
      return this.hubConnection
        .invoke('Move', move)
        .catch((err) => console.error('Error while making move:', err));
    }
    return Promise.reject('No hub connection');
  }

  public startGame(gameName: string): Promise<void> {
    if (this.hubConnection) {
      return this.hubConnection
        .invoke('Start', gameName)
        .catch((err) => console.error('Error while starting game:', err));
    }
    return Promise.reject('No hub connection');
  }

  private addListeners(): void {
    if (this.hubConnection) {
      this.hubConnection.on('ReceiveMessage', (game: Game) => {
        // console.log('Received updated game state:', game);
        this.store.dispatch(GameActions.updateGameState({ game }));
      });
      this.hubConnection.on('Timer', (timer: number) => {
        debugger;
        console.log('Timer:', timer);
      });
    }
  }

  public getConnectionStatus(): Observable<boolean> {
    return this.connectionStatus.asObservable();
  }

  ngOnDestroy(): void {
    this.stopPolling();
  }
}
