<button
  *ngIf="showInstallButton"
  (click)="installPwa()"
  class="install-button btn bg-burizaRed p-2 rounded-md"
>
  Install App
</button>
<div class="w-screen h-screen flex justify-center items-center backround-image">
  <form
    class="flex flex-col justify-center items-stretch text-center gap-4 bg-burizaWhite rounded-md p-4"
  >
    <h1 class="text-3xl font-bold text-black">Welcome to Buriza</h1>

    <p class="text-black">To create a room enter your nickname.</p>
    <input
      class="p-2 rounded-md text-black text-center focus:outline-2 outline-burizaViolet"
      type="text"
      placeholder="Enter your nickname"
      [(ngModel)]="userNickname"
      name="nickname"
    />
    <button class="btn rounded-md p-2 bg-burizaRed" (click)="createGame()">
      Create Game
    </button>
  </form>
</div>
