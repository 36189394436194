<div class="relative flex flex-col items-center justify-center">
  <app-rotating-circles *ngIf="currentlyPlaying$"></app-rotating-circles>
  <div
    class="font-bold text-center -top-2 absolute left-[1/2] rounded-full bg-burizaDark px-2 text-sm"
    [ngClass]="{ 'bg-burizaWhite text-burizaDark': currentlyPlaying$ }"
  >
    {{ (player$ | async)!.name }}
  </div>
  <img
    [src]="lastPlayedCard$ | async | cardImageUrl"
    [attr.alt]="lastPlayedCard$ | async | cardAltText"
    class="h-full w-full object-contain"
  />
  <div class="flex w-12 absolute -bottom-1 rounded-full overflow-clip text-sm">
    <div class="predictions">{{ predictedWins$ | async }}</div>
    <div class="victories">{{ actualWins$ | async }}</div>
  </div>
</div>
