<div class="flex flex-col h-full w-full p-2 bg-burizaDark gap-2">
  <div
    class="flex flex-row p-2 w-full rounded-md relative shadow-inner shadow-black"
  >
    <app-rotating-circles *ngIf="currentlyPlaying"></app-rotating-circles>
    <div
      id="top-row"
      *ngFor="let card of playerHandCards; let i = index"
      (click)="onCardClick(card)"
      class="flex-shrink-0 relative shadow-lg shadow-black rounded-md"
      [ngStyle]="{
        'z-index': !isSmallScreen ? i : 'auto',
        transform: !isSmallScreen
          ? playerHandCards.length > 4
            ? 'translateX(' +
              -i *
                ((100 * (playerHandCards.length - 4)) /
                  (playerHandCards.length - 1)) +
              '%)'
            : 'none'
          : 'none'
      }"
      [ngClass]="[
        isSmallScreen ? 'w-1/6' : 'w-1/4',
        !isCardPlayable(card) ? 'unplayable' : ''
      ]"
    >
      <img
        class="w-full shadow-3xl card"
        [src]="card | cardImageUrl"
        [alt]="card | cardAltText"
      />
    </div>
    <!-- <app-card-row [cards]="playerHandCards"></app-card-row> -->
  </div>
  <div class="bottom-row flex-grow flex justify-between">
    <button
      class="rounded-md bg-burizaRed font-extrabold py-2 px-4 inline-flex items-center justify-center"
    >
      <i class="fas fa-bars text-black text-xl"></i>
    </button>

    <button
      class="rounded-md bg-burizaRed font-extrabold py-2 px-4 inline-flex items-center justify-center"
    >
      <i class="fas fa-bars text-black text-xl"></i>
    </button>
  </div>
</div>
