import { Component, HostListener, Input, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Card, CardSign, Game, Move } from '../../../state/game/game.models'; // Adjust the import path as needed
import * as GameActions from '../../../state/game/game.actions';

import {
  selectTargetSign,
  selectPlayerHandCards,
  selectCurrentPlayer,
  selectGameName,
} from '../../../state/game/game.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-hud',
  templateUrl: './hud.component.html',
  styleUrl: './hud.component.css',
})
export class HudComponent {
  playerName: string = localStorage.getItem('username') || '';
  gameName: string | null = null;
  isSmallScreen = window.innerWidth < 400;
  currentlyPlaying: boolean = false;
  targetSign$: Observable<CardSign | undefined> = new Observable<
    CardSign | undefined
  >();
  playerHandCards: Card[] = [];

  ngOnInit() {
    this.store.select(selectCurrentPlayer).subscribe((player) => {
      this.currentlyPlaying = player === this.playerName;
    });
    this.targetSign$ = this.store.select(selectTargetSign);
    this.store
      .select(selectPlayerHandCards(this.playerName))
      .subscribe((cards) => (this.playerHandCards = cards));

    this.store.select(selectGameName).subscribe((gameName) => {
      this.gameName = gameName ?? null;
    });
  }

  constructor(private store: Store) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isSmallScreen = window.innerWidth < 400;
    console.log('isSmallScreen', this.isSmallScreen);
  }

  onCardClick(card: Card) {
    const move: Move = {
      gameName: this.gameName!,
      playerName: this.playerName!,
      card: card,
    };
    console.log(move);
    this.store.dispatch(GameActions.move({ move }));
  }

  isCardPlayable(card: Card): boolean {
    let handSigns = this.playerHandCards.map((card) => card.sign);
    let targetSign: CardSign | undefined;
    this.targetSign$.subscribe((sign) => (targetSign = sign));
    if (!handSigns.includes(targetSign!)) {
      return true;
    }
    return card.sign === targetSign || targetSign == null;
  }
  ngDoCheck() {}
  ngOnChanges(changes: SimpleChanges): void {
    console.log('Changes ', changes);
  }
}
