import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { GameApiService } from '../../services/game-api.service';
import { Router } from '@angular/router';
import { SignalRService } from '../../services/signal-r.service';
import { PlayerConnection } from './game.models';
import * as GameActions from './game.actions';

@Injectable()
export class GameEffects {
  constructor(
    private actions$: Actions,
    private gameApiService: GameApiService,
    private router: Router,
    private signalRService: SignalRService
  ) {}

  joinGame$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GameActions.joinGame),
      mergeMap(({ gameName, playerName }) =>
        this.gameApiService.joinGame(gameName, playerName).pipe(
          map((game) => {
            this.connect(playerName, game.name);
            return GameActions.joinGameSuccess({ game });
          }),
          catchError((error) => of(GameActions.joinGameFailure({ error })))
        )
      )
    )
  );

  createGame$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GameActions.createGame),
      mergeMap((action) =>
        this.gameApiService.createGame(action.playerName).pipe(
          map((game) => {
            this.connect(action.playerName, game.name);
            this.router.navigate(['/game', game.name]);
            return GameActions.createGameSuccess({ game });
          }),
          catchError((error) => of(GameActions.createGameFailure({ error })))
        )
      )
    )
  );

  predict$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GameActions.predict),
      switchMap(({ prediction }) =>
        from(this.signalRService.predict(prediction)).pipe(
          map(() => GameActions.predictSuccess()),
          catchError((error) =>
            of(GameActions.predictFailure({ error: error.toString() }))
          )
        )
      )
    )
  );

  move$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GameActions.move),
      switchMap(({ move }) =>
        from(this.signalRService.move(move)).pipe(
          map(() => GameActions.moveSuccess()),
          catchError((error) =>
            of(GameActions.moveFailure({ error: error.toString() }))
          )
        )
      )
    )
  );

  private connect(playerName: string, gameName: string): void {
    const playerConnection: PlayerConnection = {
      name: playerName,
      group: gameName,
    };
    this.signalRService
      .startConnection()
      .then(() => this.signalRService.register(playerConnection))
      .catch((error: any) => console.error('Error joining game:', error));
  }
}

export default GameEffects;
