import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as GameActions from '../../state/game/game.actions';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {
  userNickname: string = '';
  showInstallButton: boolean = false;
  private deferredPrompt: any;

  constructor(
    private router: Router,
    private store: Store,
    private forms: FormsModule
  ) {}

  @HostListener('window:beforeinstallprompt', ['$event'])
  onBeforeInstallPrompt(e: Event) {
    e.preventDefault();
    this.deferredPrompt = e;
    this.showInstallButton = true;
  }

  async installPwa() {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      const { outcome } = await this.deferredPrompt.userChoice;
      console.log(`User response to the install prompt: ${outcome}`);
      this.deferredPrompt = null;
      this.showInstallButton = false;
    }
  }

  createGame() {
    if (this.userNickname.trim()) {
      const trimmedNickname = this.userNickname.trim();
      localStorage.setItem('username', trimmedNickname);
      console.log('Creating game for user:', trimmedNickname);

      this.store.dispatch(
        GameActions.createGame({ playerName: trimmedNickname })
      );
    } else {
      console.log('Please enter a valid nickname');
    }
  }
}
