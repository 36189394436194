// Interface for a card
export interface Card {
  sign: CardSign; // CardSign enum
  value: CardValue; // CardValue enum
}

// Interface for the deck containing multiple cards
export interface Deck {
  cards: Card[];
}

export interface GameState {
  game: Game | null;
  error: any;
}

// Interface for a player's state
export interface Player {
  name: string;
  cards: Card[]; // Cards the player has
  playedCards: Card[]; // Cards the player has played
  score: number; // Current score
  predictedVictories: number; // Predicted victories for the round
  actualVictories: number; // Actual victories in the round
  scoreByRound: { [key: string]: number }; // Score per round (could be keyed by round number)
  id: string; // Unique player identifier
}

// Interface for the game state
export interface Game {
  name: string; // Name of the game
  isStarted: boolean; // Whether the game has started
  deck: Deck; // Deck of cards in the game
  players: { [key: string]: Player }; // A map of player IDs to player objects
  playingOrder: string[]; // Array of player IDs representing playing order
  predictingOrder: string[]; // Array of player IDs representing predicting order
  round: number; // Current round number
  battle: number; // Current battle number
  tableCard: Card | null; // Card on the table (or null if none)
  graveyard: Deck; // Deck of discarded cards
  maxNumberOfPlayers: number; // Maximum number of players allowed
  playersPredictions: number; // Number of players who have made predictions
  movesPlayed: number; // Number of moves played in the current battle
  isPredictingPhase: boolean; // Whether the game is in the predicting phase
  isBattlingPhase: boolean; // Whether the game is in the battling phase
  battlingMoves: any[]; // Moves made during the battle (could refine this based on more details)
  id: string; // Unique game identifier
}

// Enum for Card Signs
export enum CardSign {
  Air = 1,
  Earth = 2,
  Fire = 3,
  Water = 4,
}

// Enum for Card Values (you mentioned values between 1 and 21)
export enum CardValue {
  One = 1,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Nine,
  Ten,
  Eleven,
  Twelve,
  Thirteen,
  Fourteen,
  Fifteen,
  Sixteen,
  Seventeen,
  Eighteen,
  Nineteen,
  Twenty,
  TwentyOne,
}

export interface Prediction {
  gameName: string;
  playerName: string;
  numberOfWins: number;
}

export interface Move {
  gameName: string;
  playerName: string;
  card: Card; // Assuming you have a Card interface defined elsewhere
}

export interface PlayerConnection {
  name: string;
  connectionId?: string; // Optional field (corresponding to C#'s nullable type)
  group: string;
}
