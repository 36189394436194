import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Card, CardSign, Game } from '../../../state/game/game.models'; // Adjust the import path as needed
import { Observable } from 'rxjs'; // Adjust the import path as needed
import { Store } from '@ngrx/store';
import {
  selectPlayerNames,
  selectTableCard,
} from '../../../state/game/game.selectors';
import {
  animate,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';
@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrl: './field.component.css',
  // animations: [
  //   trigger('animation', [
  //     transition('* <=> *', [
  //       query(
  //         ':enter',
  //         [
  //           style({ opacity: 0, transform: 'scale(.7)' }),
  //           animate(
  //             '500ms ease-in',
  //             style({ opacity: 1, transform: 'scale(1)' })
  //           ),
  //         ],
  //         { optional: true }
  //       ),
  //     ]),
  //   ]),
  // ],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldComponent implements OnInit {
  players$: Observable<string[]> = new Observable<string[]>();
  tableCard$: Observable<Card | null | undefined> = new Observable<
    Card | null | undefined
  >();
  renderCount: number = 0;
  ngOnInit(): void {
    this.tableCard$ = this.store.select(selectTableCard);
    this.players$ = this.store.select(selectPlayerNames);
  }

  constructor(private store: Store<Game>) {}

  getPlayerPosition(index: number): string {
    const positions = [
      'top-left',
      'top-center',
      'top-right',
      'middle-right',
      'bottom-right',
      'bottom-center',
      'bottom-left',
      'middle-left',
    ];
    return positions[index] || '';
  }

  getGlowClass(card: Card | undefined | null): string {
    if (!card) return '';

    switch (card.sign) {
      case CardSign.Fire:
        return 'glow-F';
      case CardSign.Water:
        return 'glow-W';
      case CardSign.Air:
        return 'glow-A';
      case CardSign.Earth:
        return 'glow-E';
      default:
        return '';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('Changes ', changes);
  }
}
