<div class="grid grid-cols-3 h-full grid-rows-3 bg-burizaViolet p-4 gap-4">
  <!-- Table card (always displayed) -->
  <div [ngClass]="" class="flex flex-col items-center middle-center">
    <img
      [src]="tableCard$ | async | cardImageUrl"
      [attr.alt]="tableCard$ | async | cardAltText"
      [ngClass]="getGlowClass(tableCard$ | async)"
      class="object-contain w-full p-1"
    />
  </div>
  <!-- Players -->
  <!-- <ng-container [@animation]="players$ | async"> -->
  <ng-container>
    @for (player of players$ | async; track $index) {
    <app-player
      *ngFor="let player of players$ | async; let i = index"
      [playerName]="player"
      [ngClass]="getPlayerPosition(i)"
    />
    }

    <!-- <app-player
      [player]="player.value"
      [ngClass]="getPlayerPosition(1)"
    ></app-player>
    <app-player
      [player]="player.value"
      [ngClass]="getPlayerPosition(2)"
    ></app-player>
    <app-player
      [player]="player.value"
      [ngClass]="getPlayerPosition(3)"
    ></app-player>
    <app-player
      [player]="player.value"
      [ngClass]="getPlayerPosition(4)"
    ></app-player>
    <app-player
      [player]="player.value"
      [ngClass]="getPlayerPosition(5)"
    ></app-player>
    <app-player
      [player]="player.value"
      [ngClass]="getPlayerPosition(6)"
    ></app-player>
    <app-player
      [player]="player.value"
      [ngClass]="getPlayerPosition(7)"
    ></app-player> -->
  </ng-container>
</div>
