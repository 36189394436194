<div class="flex flex-col h-screen">
  <app-field class="w-full flex-shrink flex-grow"></app-field>
  <app-hud class="w-full max-h-[30vh] flex-shrink-0"></app-hud>
</div>

<app-modal
  [show]="showPredictionModal"
  [title]="'Predict Your Victories'"
  (close)="closePredictionModal()"
>
  <div class="prediction-content">
    <p>How many victories do you predict?</p>
    <div class="prediction-button-group">
      <button
        *ngFor="let number of predictionButtons"
        (click)="onPredict(number)"
        class="prediction-button"
      >
        {{ number }}
      </button>
    </div>
  </div>
</app-modal>
